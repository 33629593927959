import { onMounted, Ref } from 'vue'

interface Callback {
    (event: Event): void
}

export default function useDetectOutsideClick(
    component: Ref,
    callback: Callback
) {
    if (!component) return
    const listener = (event: Event) => {
        if (
            event.target !== component.value &&
            event.composedPath().includes(component.value)
        ) {
            return
        }
        if (typeof callback === 'function') {
            callback(event)
        }
    }
    onMounted(() => {
        window.addEventListener('click', listener)
    })

    return { listener }
}
